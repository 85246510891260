import React from "react";
import _ from "lodash";

import { Layout } from "../components";

const PageDocumentTemplate = ({ pageContext }) => {
  return (
    <Layout className="templated-page" pageData={pageContext}>
      <iframe
        title={
          _.get(pageContext, "document.title") || _.get(pageContext, "title")
        }
        width="100%"
        height="600px"
        src={_.get(pageContext, "document.file.url")}
      />
      <a
        href={_.get(pageContext, "document.file.url")}
        target="_blank"
        rel="noopener noreferrer"
      >
        Download Document
      </a>
    </Layout>
  );
};

export default PageDocumentTemplate;
